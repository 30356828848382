import { useContext, useEffect, useRef } from 'react';
import { Status, hasOrderExpired } from '../utils/utils';
import { AppContext } from '../contexts/AppContext';
import { LocalStorageContext } from '../contexts/LocalStorage';
import API_URL from '../settings.json'

export const API = ({ loadingCard, pendingCard, expiredCard, invalidCard, children }) => {

  const AUTO_UPDATE_RATE = 60000
  const ENDPOINT_URL = API_URL.endpoint || false

  const { global, order, customer, updateGlobal, updateOrder, updateCustomer } = useContext(AppContext);
  const { storage, addValues } = useContext(LocalStorageContext)
  const intervalRef = useRef();

  const getOrderDetails = async () => {
    try {
      const res = await fetch(ENDPOINT_URL + `/order/details?t=${global.id}`);
      if (!res.ok) {
        throw new Error('Could not fetch order details');
      }
      const result = await res.json();
      return result;
    } catch (e) {
      return {
        reason: "Order API error occurred",
        result: "FAILED"
      };
    }
  }

  const getCustomerDetails = async () => {
    try {
      if (global.id === storage.id && Object.keys(storage).length > 1) {
        return storage
      }

      const res = await fetch(ENDPOINT_URL + `/customer/details?t=${global.id}`);
      if (!res.ok) {
        throw new Error('Could not fetch customer details');
      }

      const result = await res.json();
      return result;

    } catch (e) {
      return {
        reason: "Customer API error occurred",
        result: "FAILED"
      };
    }
  }

  const fetchDetails = async () => {
    return await Promise.all(
      [getOrderDetails(), getCustomerDetails()]
    ).then(
      ([order, customer]) => {
        updateOrder(order);
        updateCustomer(customer);
        customer.result !== 'FAILED' && addValues({ id: global.id, ...customer })
        updateGlobal('isLoading', false)
      })
  }

  const autoRefresh = () => {
    // console.log("Auto refresh....");
    const interval = setInterval(async () => {
      const data = await getOrderDetails();
      updateOrder(data);
      updateGlobal('lastUpdated', new Date())

    }, AUTO_UPDATE_RATE);

    intervalRef.current = interval;
  }


  useEffect(() => {
    if (!ENDPOINT_URL) {
      console.error("Invalid endpoint URL, please contact Administrator.")
      return invalidCard
    }

    if (global.isLoading) {
      (async () => {
        await fetchDetails()
        updateGlobal('lastUpdated', new Date())
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    autoRefresh()

    return () => clearInterval(intervalRef.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  if (!global.isLoading) {
    const { delivery_status, result, drivers_name } = order

    if (result === 'FAILED') {
      clearInterval(intervalRef.current)
      return invalidCard
    }

    if (hasOrderExpired(customer.window_end_time)) {
      clearInterval(intervalRef.current)
      return expiredCard
    }

    if (delivery_status === Status.PENDING || (delivery_status === Status.INPROGRESS && !drivers_name))
      return pendingCard

    if (delivery_status !== Status.PENDING && delivery_status !== Status.INPROGRESS) {
      // console.log("FINAL PAGE, clearing timer...");
      clearInterval(intervalRef.current)
    }

    return children

  }

  return loadingCard

}