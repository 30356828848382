const Nav = ({ schema }) => {
  return (
    <div as="nav" className="bg-white">
      <div className="mx-auto px-2 h-28 sm:h-48">
        <div className="relative flex items-center justify-between h-16 sm:h-24">
          <div className="flex-1 flex items-center justify-center">
            <div className="flex-shrink-0 flex items-center">
              <img
                className="block h-6 sm:h-8 w-auto"
                src={schema.logo}
                alt="Iceland Logo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Nav;